<template>
  <v-dialog v-model="show" max-width="800px" scrollable>
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          Gráficos
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <template v-if="loading">
          <v-skeleton-loader
            class="mx-auto my-3"
            type="paragraph"
          ></v-skeleton-loader>
        </template>

        <p v-if="!loading && charts.length === 0" class="my-5">
          <i>Nenhuma pergunta fechada encontrada nesta pesquisa</i>
        </p>

        <template v-else>
          <v-row>
            <v-col v-for="(chart, index) in charts" :key="index" cols="6">
              <core-chart :id="index" :data="chart" />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import surveysAnswersApi from '@/api/surveys-answers'
import utilsChart from '@/utils/chart'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      charts: [],
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  mounted() {
    this.loadAnswers()
  },

  methods: {
    async loadAnswers() {
      try {
        this.loading = true

        const response = await surveysAnswersApi.closeAnswersCharts(
          this.surveyId,
        )

        const groupByQuestion = this.groupBy(response.data.data, 'questionId')

        for (const question in groupByQuestion) {
          this.charts.push(this.mountChartObject(groupByQuestion[question]))
        }
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    mountChartObject(question) {
      return {
        type: 'pie',
        data: {
          labels: question.map(e => e.questionItemTitle),
          datasets: [
            {
              data: question.map(e => e.count),
              backgroundColor: Object.values(utilsChart.COLORS),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                const sum = ctx.dataset.data.reduce((partialSum, v) => partialSum + parseFloat(v), 0)
                return ((value * 100) / sum).toFixed(0) + '%'
              },
              color: '#fff',
            },
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: question[0].questionTitle.match(/.{1,60}/g),
            },
          },
        },
      }
    },

    groupBy(objectArray, property) {
      return objectArray.reduce(function(acc, obj) {
        var key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
  },
}
</script>
